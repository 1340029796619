import React, { useEffect, useState } from 'react';
import reload from '../assets/icons/reload.png';
import customsemail from '../assets/icons/email-settings.png';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import ReactQuill from 'react-quill';
import '../App.css'
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL || '';

const Websitelist = () => {


  
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setloading] = useState(false);
  
  const [loadingEmail, setLoadingEmail] = useState([]);
  const [Cloading, setCloading] = useState(false);
  const [status, setStatus] = useState([]);

  const [reports, setReports] = useState([]);
  const [Campaignlist, setCampaignlist] = useState([]);

  

  let { id } = useParams();

  const [Campaignid, setCampaignid] = useState(id);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10;

  const [campaign, setCampaign] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [keywords, setKeywords] = useState('');
  const [total, setTotal] = useState('');

  const [searching, setSearching] = useState(false); // State to track search

 const[Selectcheckbox, setSelectcheckbox] = useState([]);

 const [ReportID, setReportId] = useState('');
 const [Reportid, setReportid] = useState('');

 const [showModal, setShowModal] = useState(false);
 const [updateModal, setUpdateModal] = useState(false);
 const [Emailname, setEmailname] = useState('');
 const [EmailTitle, setEmailTitle] = useState('');
 const [Emailmessage, setEmailmessage] = useState('');
 const [CEmailmessage, setCEmailmessage] = useState('');
 const modules = {
  toolbar: [
    [{ 'font': [] }, { 'size': [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['link', 'image', 'video'],
    ['clean']
  ],
};
 
 const serverUrl = apiUrl;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please upload a CSV file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      const response = await axios.post(apiUrl + '/api/upload-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setStatus(response.data);
    } catch (error) {
      console.error('Error uploading file', error);
      alert('Error uploading file');
    } finally {
      setUploading(false);
    }
  };

  const handleDownload = async () => {

    const url = process.env.PUBLIC_URL + '/assets/csv/seo.csv'; // Path to your CSV file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'seo.csv'); // Specify the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };


  useEffect(() => {
    if (!searching) { // Only fetch reports if not searching
      fetchReports();
      fetchCampaignlist();
      if (Campaignid) {
        setCampaign(Campaignid);
        handleSearch();
      }
    } else {
      handleSearch();
      
    }
  }, [page, searching, Campaignid]); 
  // Add 'id' to the dependency array

 

  const fetchCampaignlist = async () => {
    const response = await axios.get(apiUrl + '/api/campaign/list', {});
    setCampaignlist(response.data.data);
   
  };


  const fetchReports = async () => {
    try {

      const response = await axios.get(apiUrl + '/api/reports', {
        params: {
          limit,
          page
        }
      });
      setReports(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error fetching reports', error);
    }
  };



  const handleSearch = async () => {
    try {
      const response = await axios.get(apiUrl + '/api/search', {
        params: {
          campaign,
          fromDate,
          toDate,
          keywords,
          limit,
          page
        }
      });


      setReports(response.data.data || []);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
      setSearching('s')
    } catch (error) {
      console.error('Error searching reports', error);

    }

  };

  const reSendemail = async () => {
    
    if (Selectcheckbox.length === 0) {
      
      alert('Select the checkbox!');
    } else {
     setloading(true);
     try {
        const response = await axios.put(apiUrl + '/api/reSendemail/', {
          Selectcheckbox: Selectcheckbox
        });
        if (response.data) {
          alert('ReSent successfully');
          setShowModal(false);
          resetForm();
          await fetchReports();
      }
     } catch (error) {
      console.log('reSendemail',error);
     }finally{
      setloading(false);
     } 
    }
  };

  const Sendemail = async (id) => {

 // Prevents the default form submission
    const Selectcheckbox = Array.isArray(id) ? id : [id];

     try {
        const response = await axios.put(apiUrl + '/api/reSendemail/', {
          Selectcheckbox: Selectcheckbox
        });
        if (response.data) {
          alert('ReSent  successfully');
          setShowModal(false);
          resetForm();
          await fetchReports();
      }
     } catch (error) {
      console.log('reSendemail',error);
     }finally {
      setLoadingEmail(loadingEmail.filter(id => id !== id));
    }
    
  };

  const reSendcustumemail = async (event) => {
    event.preventDefault(); // Prevents the default form submission

    if (Selectcheckbox.length === 0) {
        alert('Select the checkbox!');
    } else {
        setCloading(true);
        try {
            const response = await axios.put(apiUrl + '/api/reSendcustumemail/', {
                Selectcheckbox,
                Emailname,
                EmailTitle,
                Emailmessage
            });
            if (response.data) {
                alert('Custom Sent successfully');
                setShowModal(false);
                resetForm();
                await fetchReports();
            }
        } catch (error) {
            console.log('reSendemail', error);
        } finally {
            setCloading(false);
        }
    }
};
const Sendcustumemail = async (event) => {
  event.preventDefault(); // Prevents the default form submission

  const Selectcheckbox = Array.isArray(ReportID) ? ReportID : [ReportID];
  setCloading(true); // Ensure setCloading is not causing rapid re-renders
// console.log(Selectcheckbox);
// return false;
  const Emailmessage = CEmailmessage;

  try {
      const response = await axios.put(apiUrl + '/api/reSendcustumemail/', {
          Selectcheckbox,
          Emailname,
          EmailTitle,
          Emailmessage
      });
      if (response.data) {
          alert('Custom Sent successfully');
          setUpdateModal(false);
          resetForm();
          await fetchReports();
      }
  } catch (error) {
      console.log('reSendemail', error);
  } finally {
      setCloading(false); // Ensure setCloading is not causing rapid re-renders
  }
};




  const handleRsendEmail = async (reportId) => {
    setLoadingEmail([...loadingEmail, reportId]);
    
      await Sendemail(reportId);
     
  };


const sendcustomsemail = (id) => {
  setReportId(id);
  setUpdateModal(true);
}



const closeModal = () => {
  setUpdateModal(false);
  resetForm();
}

  const Delete = (id) => {
    if (window.confirm('Are you sure you want to delete this data?')) {
      axios.delete(apiUrl + `/api/reports/${id}`)
        .then(response => {
          setReports(reports.filter(report => report.id !== id));

        })
        .catch(error => {
          console.error('There was an error deleting the record!', error);
        });
    }
  };

  

  const Update = async (id, url, email) => {

    if (!url || !email) {
      alert('URL and email are required');
      return;
    }

    try {
      const response = await axios.put(apiUrl + `/api/reports/${id}`, {
        id,
        url,
        email
      });
      // setMessage(response.data.message);
      // setError('');

      // // Refresh reports data
      await fetchReports();
    } catch (error) {
      console.error('Error updating report', error);
      // setMessage('Error updating report');
      // setError('');
    }
  };


  const handleCheckboxChange = (id) => {
    setSelectcheckbox(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(reportId => reportId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const resetForm = () => {
    setEmailname('');
    setEmailTitle('');
    setEmailmessage('');
    setCEmailmessage('');
    setReportId('');
    setSelectcheckbox('');
  };

  const statusClasses = {
    Sent: 'text-success',
    Resent: 'text-success',
    CustomSent: 'text-success',
    Fail: 'text-danger',
    ResentFail: 'text-danger',
    CustomFail: 'text-danger',
    null: '',
  };
  
  const statusText = {
    Sent: 'Sent',
    Resent: 'Resent Email',
    CustomSent: 'Custom Sent Email',
    Fail: 'Fail',
    ResentFail: 'Resent Fail',
    CustomFail: 'Custom Fail',
    null: '',
  };

  return (
    <div className="Website-list">
      <div className="col">
        <Navbar />
      </div>
      <div>
        <div className="pt-4"></div>
        <Sidebar />
        <div className="pt-5"></div>
        <div className='main-content'>
          {/* <h2>Lighthouse Report Generator</h2> */}

          <div className="row r">
            <div className="col ">
              <div className="title">
                <h2>All Campaign Report</h2>
              </div>
              <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    Menu
                  </li>
                  <li className="breadcrumb-item active text-danger" aria-current="page">
                  All Campaign Report
                  </li>

                </ol>
              </nav>

            </div>
            {/* <div className="col text-end">

              <form onSubmit={handleSubmit}>
                <input type="file" id="fileInput" name="file" accept=".csv" onChange={handleFileChange} />
                <button className='btn btn-primary' type="submit" disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Upload CSV'}
                </button>
              </form>
              <div className="col text-end py-4">

                <a className='pointer' onClick={handleDownload}>Download Sample file</a>
              </div>
            </div> */}
          <div className="col text-end container">
            <div className="row">
              <div className="col">
              <button type="button" class="btn btn-primary" onClick={reSendemail} disabled={loading} >
                {loading ? (        // Show different text/icon when loading
                <>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="sr-only"> Sending...</span>
                </>
              ) : (
                <>
                   <img src={reload} alt="icon" style={{ width: '20px', height: '20px', color: '#FFFFFF' }} /> Re send Email
                </>
              )}
                
                </button>
              </div>
              <div className="col-auto">
              <button type="button" class="btn btn-primary" onClick={() => setShowModal(true)} disabled={Selectcheckbox.length === 0}>  <img src={customsemail} alt="icon" style={{ width: '20px', height: '20px', color: '#FFFFFF' }} /> Send custom Email</button>
              </div>
            </div>
          
          </div>

          <div className={`modal fade bd-example-modal-lg ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} id="newsmpt" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                      <div className="modal-content">
                        <form onSubmit={reSendcustumemail} >
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Send custom Email</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                          </div>
                          <div className="modal-body">
                            
                          <div className="mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Email Templet Name</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            id="lname"
                            name="lname"
                            value={Emailname}
                            onChange={(e) => setEmailname(e.target.value)}
                            required
                            />
                          </div>
                          <div className="mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Email Templet Title</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            id="titale"
                            name='titale' 
                            value={EmailTitle}
                            onChange={(e) => setEmailTitle(e.target.value)}
                            required
                            />
                          </div>
                          
                          <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">Email Templet Body Message</label>
                            <ReactQuill 
                                className='h-50'
                                value={Emailmessage} 
                                onChange={setEmailmessage} 
                                modules={modules}
                                theme="snow" 
                                required 
                                  
                            />
                          </div>
                         
                         
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"  onClick={() => setShowModal(false)}>Close</button>
                            <button type="submit" className="btn btn-primary" >
                              {Cloading ? (        // Show different text/icon when loading
                                  <>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only"> Sending...</span>
                                  </>
                                ) : (
                                  <>
                                    Send Email
                                  </>
                                )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {updateModal && (
                        <div className="modal fade show" style={{ display: 'block' }}>
                          <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                              <form onSubmit={Sendcustumemail}>
                              <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Send custom Email</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal()}></button>
                              </div>
                              <div className="modal-body">
                            
                                <div className="mb-3">
                                  <label for="exampleFormControlInput1" className="form-label">Email Templet Name</label>
                                  <input 
                                  type="text" 
                                  className="form-control" 
                                  id="lname"
                                  name="lname"
                                  value={Emailname}
                                  onChange={(e) => setEmailname(e.target.value)}
                                  required
                                  />
                                </div>
                                <div className="mb-3">
                                  <label for="exampleFormControlInput1" className="form-label">Email Templet Title</label>
                                  <input 
                                  type="text" 
                                  className="form-control" 
                                  id="titale"
                                  name='titale' 
                                  value={EmailTitle}
                                  onChange={(e) => setEmailTitle(e.target.value)}
                                  required
                                  />
                                </div>
                                
                                <div className="mb-3">
                                  <label for="exampleFormControlTextarea1" className="form-label">Email Templet Body Message</label>
                                  <ReactQuill 
                                      className=''
                                      value={CEmailmessage} 
                                      onChange={setCEmailmessage} 
                                      modules={modules}
                                      theme="snow" 
                                      required 
                                        
                                  />
                                </div>
                                </div>
                                <div className="modal-footer">
                                <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"  onClick={() => closeModal()}>Close</button>
                                <button type="submit" className="btn btn-primary" >
                                  {Cloading ? (        // Show different text/icon when loading
                                      <>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span class="sr-only"> Sending...</span>
                                      </>
                                    ) : (
                                      <>
                                        Send Email
                                      </>
                                    )}
                                </button>
                              </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}

          </div>
          <div className='pb-4'>
            <form onSubmit={handleSearch}>
              <div className="text-center pt-4">
                <h4>Search</h4>
              </div>
              <div className="row container">
                <div className="col">
                  <div className="row">
                    <div className="col-auto"><label htmlFor="" className='pt-2'>Campaign List </label></div>
                    <div className="col-6">
                      <select className="form-select" aria-label="Default select example" value={campaign} onChange={(e) => setCampaign(e.target.value)}>
                        <option selected>Select List...</option>
                        {Campaignlist.map(campaignl => (
                          <option key={campaignl.id} value={campaignl.id}>{campaignl.campaign_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-auto"> <label htmlFor="" className='pt-2'>From date</label></div>
                    <div className="col"> <input type="date" className='form-control' value={fromDate} onChange={(e) => setFromDate(e.target.value)} /></div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-auto"> <label htmlFor="" className='pt-2'>To date</label></div>
                    <div className="col"> <input type="date" className='form-control' value={toDate} onChange={(e) => setToDate(e.target.value)} /></div>
                  </div>
                </div>
              </div>
              <div className="row pt-3 container">
                <div className="col">
                  <div className="row">
                    <div className="col-auto"> <label htmlFor="" className='pt-2'>Search by Keywords</label></div>
                    <div className="col-5"> <input type="text" className='form-control' placeholder='Search...' value={keywords} onChange={(e) => setKeywords(e.target.value)} /></div>
                  </div>
                </div>
                <div className="col"><button type="submit" class="btn btn-primary float-end">Go</button></div>

              </div>


            </form>
          </div>

          <div>

          </div>

          <div className="reports-table mt-3">
            <h2>Reports Data</h2>
            <div className=''>
              {status.length > 0 && (
                <div className="status-container mt-3">
                  <h2>Processing Status</h2>
                  <ul>
                    {status.map((entry, index) => (
                      <li key={index}>
                        <strong>{entry.website}</strong> ({entry.email}): {entry.status}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="titel-hrader table-responsive table-responsive-scrollbar-top">
              <table className='table table-hover ' style={{"width" : "200%"}}>
                <thead>
                  <tr>
                    <th>Checkbox</th>
                    <th>Campaign name</th>
                    <th>Data List name</th>
                    <th>Webmaster name</th>
                    <th>Website</th>
                    <th>Email</th>
                    <th>Email Templet used</th>
                    <th>SMTP used</th>
                    <th>Report public url</th>
                    <th>Last date email send</th>
                    <th>Status</th>
                    <th>Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">No Campaign Data</td>
                    </tr>
                  ) : (
                    reports.map((report) => (
                      <tr key={report.id}>
                         <td>
                          <input
                             type="checkbox"
                             checked={Selectcheckbox.includes(report.id)}
                             onChange={() => handleCheckboxChange(report.id)}

                          />
                        </td>
                        
                        <td>{report.campaign_name}</td>
                        <td>{report.datalist_name}</td>
                        <td>{report.webmaster_name}</td>
                        <td>{report.url}</td>
                        <td>{report.email}</td>
                        <td>{report.email_templet_name}</td>
                        <td>{report.mail_mailer}</td>
                        <td className={statusClasses[report.status] || ''}>
                          {statusText[report.status] || ''}
                        </td>
                        <td>{report.created_at}</td>
                        <td><a href={`${serverUrl}/${report.pdf_path}`} target='_blank'>View</a></td>
                        <td>
                          <div class="row">
                            <div class="col-sm">
                              <button type="button" class="btn btn-success"  onClick={() => handleRsendEmail(report.id)}>
                              {loadingEmail.includes(report.id) ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                <img src={reload} alt="icon" style={{ width: '20px', height: '20px', color: '#FFFFFF' }} />
                              )}
                               </button>
                            </div>
                            <div class="col-sm">
                              <button type="button" class="btn btn-primary" onClick={() => sendcustomsemail(report.id)}><img src={customsemail} alt="icon" style={{ width: '24px', height: '24px', color: '#FFFFFF' }} /></button>
                            </div>
                            {/* <div class="col-sm">
                              <button type="button" class="btn btn-danger pt-1" onClick={() => Delete(report.id)}><i class="bi bi-trash3-fill"></i></button>
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              </div>
              <div className="d-flex justify-content-center">
                <nav aria-label="...">
                  {limit < total && (
                    <ul class="pagination">
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index + 1} className={`page-item ${page === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => setPage(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </nav>
              </div>




              <nav>
                <ul className="pagination">

                </ul>
              </nav>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Websitelist;