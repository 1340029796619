import React from 'react';
import LighthouseForm from './LighthouseForm';
import logo from './assets/image/xtrazcon-new-logo-removebg-preview.png';

const Home = () => {

  return (
    <div>
    <div className="form-container py-3 hv-100">
    <img src={logo} alt="My Image" class="img-fluid mx-auto d-block"  />
      <div className='p-2'>
       <div className="text-center mb-4">
        <h1>Lighthouse Report Generator</h1>
        <h2>Generate Lighthouse Report®</h2>
        <p>Grade your website in seconds. Then learn how to improve it for free.</p>
      </div>
      <LighthouseForm />
     
      </div>
    </div>
    </div>
  );
};

export default Home;