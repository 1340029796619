import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import {useNavigate, useParams} from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL || '';

function EditTemplate() {
  const { id } = useParams();

  const [Emailname, setEmailname] = useState('');
  const [EmailTitle, setEmailTitle] = useState('');
  const [Emailmessage, setEmailmessage] = useState('');
  const [Status, setStatus] = useState('');

  const Navigate = useNavigate();

  const Backpage = () => {
    Navigate('/email-templet');
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  useEffect(() => {
    fetch(apiUrl+`/api/emailtemplet/${id}`)
      .then(response => response.json())
      .then(data => {
        setEmailname(data.email_templet_name);
        setEmailTitle(data.email_templet_title);
        setEmailmessage(data.email_templet_body_message);
        setStatus(data.status);
      });
  }, [id]);

  const Update = (e) => {
    e.preventDefault();
    const updateData = { Emailname, EmailTitle, Emailmessage, Status };

    fetch(apiUrl+`/api/emailtemplet/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updateData)
    })
    .then(response => response.text())
    .then(data => {
      console.log('Success:', data);
      alert('Data updated successfully');
      Navigate('/email-templet');
      
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error updating data');
    });
  };

 
  return (
    <div>
    <div className="Website-list">
<div className="col">
  <Navbar />
</div>
<div>
  <div className="pt-4"></div>
  <Sidebar />
  <div className="pt-5"></div>
    <div className="main-content">
  

  <div class="page-header">
          <div class="row m-0">
            <div class="col-auto">
              <button type="button" class="btn btn-primary rounded-5" onClick={Backpage}><i class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-md-5">
            <h4>ADD New Email Templet</h4>
            </div>
          </div>
      </div>
      <div className="p-2"></div>
      <div className="page-body">
          
      <div className="container">
      <form onSubmit={Update}>
      <div className="mb-3">
        <label for="exampleFormControlInput1" className="form-label">Email Templet Name</label>
        <input 
        type="text" 
        className="form-control" 
        id="lname"
        name="lname"
        value={Emailname}
        onChange={(e) => setEmailname(e.target.value)}
        required
        />
      </div>
      <div className="mb-3">
        <label for="exampleFormControlInput1" className="form-label">Email Templet Subject</label>
        <input 
        type="text" 
        className="form-control" 
        id="titale"
        name='titale' 
        value={EmailTitle}
        onChange={(e) => setEmailTitle(e.target.value)}
        required
        />
      </div>
      <div className="mb-3">
      <label for="exampleFormControlInput1" className="form-label">Status</label>
           <select 
           className="form-select" 
           aria-label="Default select example" 
           id='Status' 
           name='status' 
           value={Status} 
           onChange={(e) => setStatus(e.target.value)}
           >
            <option disabled>select status</option>
            <option value="Active">Active</option>
            <option value="Deactive">Deactive</option>
          </select>
      </div>
      <div className="mb-3">
        <label for="exampleFormControlTextarea1" className="form-label">Email Templet Body Message</label>
        <ReactQuill 
            className='h-50'
            value={Emailmessage} 
            onChange={setEmailmessage} 
            modules={modules}
            theme="snow" 
            required 
              
        />
      </div>
      
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary" >Update</button>
      </div>
      <p></p>
      </form>
      </div>
      </div>

      
      </div>
  </div>
</div>

</div>
  );
}

export default EditTemplate;
