import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const NavigationBar  = () => {
  return (
    <div>
    
    <Navbar bg="dark" variant="dark" expand="lg" className='fixed-top'>
    
      <Navbar.Brand href="/website-list" className='ps-4'>SEO</Navbar.Brand>
      
     
     
    </Navbar>
    
    
    </div>
  );
};

export default NavigationBar;