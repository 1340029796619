import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import {useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL || '';

const EmailSender = () => {
  const [Emailname, setEmailname] = useState('');
  const [EmailTitle, setEmailTitle] = useState('');
  const [Emailmessage, setEmailmessage] = useState('');
  const [Status, setStatus] = useState('');

  const navigate = useNavigate();

  const Backpage = () => {
    navigate('/email-templet');
  };
  
  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const savedata = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl+'/api/emailtemplet', { Emailname, EmailTitle, Emailmessage, Status });

      if (response.data) {
        alert('Email Templet inserted successfully!');
        navigate('/email-templet');
        
      }
    } catch (error) {
      console.error('Campaign data insertion unsuccessful!', error);
      alert('Error not inserted');
    }
  };

  return (
    <div>
    <div className="Website-list">
<div className="col">
  <Navbar />
</div>
<div>
  <div className="pt-4"></div>
  <Sidebar />
  <div className="pt-5"></div>
    <div className="main-content">
  

  <div class="page-header">
          <div class="row m-0">
            <div class="col-auto">
              <button type="button" class="btn btn-primary rounded-5" onClick={Backpage}><i class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-md-5">
            <h4>ADD New Email Templet</h4>
            </div>
          </div>
      </div>
      <div className="p-2"></div>
      <div className="page-body">
          
      <div className="container">
      <form onSubmit={savedata}>
      <div className="mb-3">
        <label for="exampleFormControlInput1" className="form-label">Email Templet Name</label>
        <input 
        type="text" 
        className="form-control" 
        id="lname"
        name="lname"
        value={Emailname}
        onChange={(e) => setEmailname(e.target.value)}
        required
        />
      </div>
      <div className="mb-3">
        <label for="exampleFormControlInput1" className="form-label">Email Templet Subject</label>
        <input 
        type="text" 
        className="form-control" 
        id="titale"
        name='titale' 
        value={EmailTitle}
        onChange={(e) => setEmailTitle(e.target.value)}
        required
        />
      </div>
      <div className="mb-3">
      <label for="exampleFormControlInput1" className="form-label">Status</label>
           <select 
           className="form-select" 
           aria-label="Default select example" 
           id='Status' 
           name='status' 
           value={Status} 
           onChange={(e) => setStatus(e.target.value)}
           >
            <option selected>select status</option>
            <option value="Active">Active</option>
            <option value="Deactive">Deactive</option>
          </select>
      </div>
      <div className="mb-3">
        <label for="exampleFormControlTextarea1" className="form-label">Email Templet Body Message</label>
        <ReactQuill 
            className='h-100'
            style={{ height: '200px' }}
            value={Emailmessage} 
            onChange={setEmailmessage} 
            modules={modules}
            theme="snow" 
            required 
              
        />
      </div>
      
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary" >Save</button>
      </div>
      <p></p>
      </form>
      </div>
      </div>

      
      </div>
  </div>
</div>

</div>
  );
};

export default EmailSender;
