import React from 'react';

import { Link } from 'react-router-dom';

const Sidebar = ({ setAuth }) => {

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
};

  return (
    <div>
    
      
    <div className="bg-dark text-white p-3" style={{ height: '100vh', width: '250px', position: 'fixed' }}>
    <nav className="navbar flex-column bg-dark text-white" data-bs-theme="dark">
          
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link active" to="/website-list">All Campaign Report</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/Campaign-schedule">Campaign Schedule</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/data-list">Data List</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/email-templet">Email Templet</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/smtp">SMTP</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active"onClick={handleLogout}>Log Out</Link>
              </li>
            </ul>
          
        </nav>
    
    </div>
     
    
    </div>
  );
};

export default Sidebar;