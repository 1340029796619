import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL || '';


const LighthouseForm = () => {
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [report, setReport] = useState('');
  const path = apiUrl;
  const report1 = report.replace(/\\/g, '/');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    setReport('');
    try {
      const response = await axios.post(apiUrl+'/api/lighthouse', { url, email });
      setMessage(response.data.message);
      setReport(response.data.reportHtml);
    } catch (error) {
      setMessage('Error requesting report. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const hideReport = () => {
    setReport('');
    setEmail(null);
    setUrl(null);
    setMessage(null);
  };


  
  return (
    <div>       
    <div className='d-flex justify-content-center container'>
    
    {report && (
    <button className='btn btn-primary' onClick={hideReport} >Check Other</button>
    )}
    {!report && (
      <form onSubmit={handleSubmit} style={{ width: '490px' }}>
        <div className="form-group floating-label mb-4">
          <input  type="url" className="form-control input border-bottom" id="url"  name="url"  value={url} placeholder=" "  onChange={(e) => setUrl(e.target.value)} required />
          <label htmlFor="website" className='label'>Website</label>
          
        </div>
        <div className="form-group floating-label mb-4">
          <input type="email" className="form-control input"  name="email" id="email"  placeholder=" "  value={email} onChange={(e) => setEmail(e.target.value)} required />
          <label htmlFor="email" className='label'>Email</label>
        </div>
        <div className="mt-4 text-center">
        <p className="small ">
          We're committed to your privacy. HubSpot uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our <a href="#" className="text-warning">Privacy Policy</a>.
          <Link className="nav-link active" to="/login">Login</Link>
        </p>
      </div>
        <div>
        {message && <p className='text fw-semibold'>{message}</p>}
        </div>
        <button type="submit" className="btn btn-warning mt-3 w-100" disabled={!url || !email || loading}>
          {loading ? 'Submitting...it will take upto 60 sec' : 'Submit'}
        </button>
      </form>
    )}
      
    </div>
    {report && (
        <div className='col-md-12'>
          <h3>Lighthouse Report</h3>
          <iframe
            src={path+'/'+report1}
            title="Lighthouse report"
            style={{ width: '100%', height: '600px', border: '1px solid #ccc' }}
          />
        </div>
      )}
      
       
</div>
  );
};

export default LighthouseForm;
