// src/components/Contact.js
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
const apiUrl = process.env.REACT_APP_API_URL || '';



const Smpt = () => {

  const [Mailer, setMailer] = useState('');
  const [HOST, setHost] = useState('');
  const [POST, setPost] = useState('');
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [Encryption, setEncryption] = useState('');
  const [FromAddress, setFromAddress] = useState('');
  const [FromName, setFromName] = useState('');
  
  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const limit = 10;

  const [currentRecord, setCurrentRecord] = useState({ id: '', mail_mailer: '', mail_host: '', mail_port: '', mail_username: '', mail_password: '', mail_encryption: '', mail_form_address: '', mail_form_name: '' });

  const fetchReports = async () => {
    try {
      const response = await axios.get(apiUrl+'/api/smtp', {
        params: {
          limit,
          page
        }
      });
      setReports(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error fetching reports', error);
      
    }
  };

  const savedata = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl+'/api/smtp', { Mailer, HOST, POST, Username, Password, Encryption, FromAddress, FromName });

      if (response.data) {
        alert('SMTP data inserted successfully!');
        await fetchReports(); // Fetch the updated reports
        resetForm();
        setShowModal(false);// Close the modal after the data is inserted and fetched
      }
    } catch (error) {
      console.error('SMTP data insertion unsuccessful!', error);
      alert('Error not inserted');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(apiUrl+`/api/smtp/${currentRecord.id}`, currentRecord);
      setReports(reports.map(report => 
        report.id === currentRecord.id ? currentRecord : report
        
      ));
      alert('SMTP data update successfully!');
      setUpdateModal(false);
    } catch (error) {
      console.error('There was an error updating the record!', error);
    }
  };

  
  const Delete = (id) => {
    if (window.confirm('Are you sure you want to delete this data?')) {
    axios.delete(apiUrl+`/api/smtp/${id}`)
      .then(response => {
        setReports(reports.filter(report => report.id !== id));

      })
      .catch(error => {
        console.error('There was an error deleting the record!', error);
      });
    }
  };

  const resetForm = () => {
    setMailer('');
    setHost('');
    setPost('');
    setUsername('');
    setPassword('');
    setEncryption('');
    setFromAddress('');
    setFromName('');
  };

  useEffect(() => {
    fetchReports();
  }, [page]);

  const handleShowModal = (record) => {
    setCurrentRecord(record);
    setUpdateModal(true);
  };


  const handleCloseModal = () => {
    setUpdateModal(false);
    setCurrentRecord({id: '', mail_mailer: '', mail_host: '', mail_port: '', mail_username: '', mail_password: '', mail_encryption: '', mail_form_address: '', mail_form_name: '' });
  };

  
  return (
    <div>
    <div className="Website-list">
      <div className="col">
        <Navbar />
      </div>
      <div>
        <div className="pt-4"></div>
        <Sidebar />
        <div className="pt-5"></div>
          <div className="main-content">
        
  
        <div class="page-header">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="title">
                            <h4>SMTP</h4>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                Menu
                            </li>
                            <li className="breadcrumb-item active text-danger" aria-current="page">
                            SMTP
                            </li>
                            
                        </ol>
                    </nav>
                    </div>
                    <div className="col">
                    <button type="button" className="btn btn-primary float-end" onClick={() => setShowModal(true)}>
                    Add new SMTP
                  </button>
                    </div>
                    
                </div>
               
            </div>
           {/* Modal */}
           
           <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} id="newsmpt" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <form onSubmit={savedata}>
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">New SMTP</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                    </div>
                    <div className="modal-body">
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input 
                          type="text" 
                          name='mailer' 
                          id="mailer" 
                          className="form-control" 
                          placeholder='Mailer' 
                          value={Mailer} onChange={(e) => setMailer(e.target.value)} 
                          required 
                        />
                      </div>
                      <div className="row">
                        <div className="col">
                          <div data-mdb-input-init className="form-outline mb-4">
                            <input 
                              type="text" 
                              name='host' 
                              id="host" 
                              className="form-control" 
                              placeholder='HOST' 
                              value={HOST} onChange={(e) => setHost(e.target.value)} 
                              required 
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div data-mdb-input-init className="form-outline mb-4">
                            <input 
                              type="text" 
                              name='post' 
                              id="post" 
                              className="form-control" 
                              placeholder='PORT' 
                              value={POST} onChange={(e) => setPost(e.target.value)} 
                              required 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div data-mdb-input-init className="form-outline mb-4">
                            <input 
                              type="text" 
                              name='username' 
                              id="username" 
                              className="form-control" 
                              placeholder='Username' 
                              value={Username} onChange={(e) => setUsername(e.target.value)} 
                              required 
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div data-mdb-input-init className="form-outline mb-4">
                            <input 
                              type="password" 
                              name='password' 
                              id="password2" 
                              className="form-control" 
                              placeholder='Password' 
                              value={Password} onChange={(e) => setPassword(e.target.value)} 
                              required 
                            />
                          </div>
                        </div>
                      </div>
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input 
                          type="text" 
                          name='encryption' 
                          id="encryption" 
                          className="form-control" 
                          placeholder='Encryption' 
                          value={Encryption} onChange={(e) => setEncryption(e.target.value)} 
                          required 
                        />
                      </div>
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input 
                          type="text" 
                          name='fromaddress' 
                          id="fromaddress" 
                          className="form-control" 
                          placeholder='From Address' 
                          value={FromAddress} onChange={(e) => setFromAddress(e.target.value)} 
                          required 
                        />
                      </div>
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input 
                          type="text" 
                          name='fromname' 
                          id="fromname" 
                          className="form-control" 
                          placeholder='From Name' 
                          value={FromName} onChange={(e) => setFromName(e.target.value)} 
                          required 
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                      <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {updateModal && (
                        <div className="modal fade show" style={{ display: 'block' }}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <form onSubmit={handleUpdate}>
                                <div className="modal-header">
                                  <h5 className="modal-title">Update SMTP Data</h5>
                                  <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                </div>
                                <div className="modal-body">
                                <div data-mdb-input-init className="form-outline mb-4">
                                  <input 
                                    type="text" 
                                    name='mailer' 
                                    id="mailer" 
                                    className="form-control" 
                                    placeholder='Mailer' 
                                    value={currentRecord.mail_mailer}
                                    onChange={(e) => setCurrentRecord({ ...currentRecord, mail_mailer: e.target.value })}
                                    required 
                                  />
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div data-mdb-input-init className="form-outline mb-4">
                                      <input 
                                        type="text" 
                                        name='host' 
                                        id="host" 
                                        className="form-control" 
                                        placeholder='HOST' 
                                        value={currentRecord.mail_host}
                                        onChange={(e) => setCurrentRecord({ ...currentRecord, mail_host: e.target.value })}
                                        required 
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div data-mdb-input-init className="form-outline mb-4">
                                      <input 
                                        type="text" 
                                        name='post' 
                                        id="post" 
                                        className="form-control" 
                                        placeholder='PORT' 
                                        value={currentRecord.mail_port}
                                        onChange={(e) => setCurrentRecord({ ...currentRecord, mail_port: e.target.value })}
                                        required 
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div data-mdb-input-init className="form-outline mb-4">
                                      <input 
                                        type="text" 
                                        name='username' 
                                        id="username" 
                                        className="form-control" 
                                        placeholder='Username' 
                                        value={currentRecord.mail_username}
                                        onChange={(e) => setCurrentRecord({ ...currentRecord, mail_username: e.target.value })}
                                        required 
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div data-mdb-input-init className="form-outline mb-4">
                                      <input 
                                        type="password" 
                                        name='password' 
                                        id="password2" 
                                        className="form-control" 
                                        placeholder='Password' 
                                        value={currentRecord.mail_password}
                                        onChange={(e) => setCurrentRecord({ ...currentRecord, mail_password: e.target.value })}
                                        required 
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div data-mdb-input-init className="form-outline mb-4">
                                  <input 
                                    type="text" 
                                    name='encryption' 
                                    id="encryption" 
                                    className="form-control" 
                                    placeholder='Encryption' 
                                    value={currentRecord.mail_encryption}
                                    onChange={(e) => setCurrentRecord({ ...currentRecord, mail_encryption: e.target.value })}
                                    required 
                                  />
                                </div>
                                <div data-mdb-input-init className="form-outline mb-4">
                                  <input 
                                    type="text" 
                                    name='fromaddress' 
                                    id="fromaddress" 
                                    className="form-control" 
                                    placeholder='From Address' 
                                    value={currentRecord.mail_form_address}
                                    onChange={(e) => setCurrentRecord({ ...currentRecord, mail_form_address: e.target.value })}
                                    required 
                                  />
                                </div>
                                <div data-mdb-input-init className="form-outline mb-4">
                                  <input 
                                    type="text" 
                                    name='fromname' 
                                    id="fromname" 
                                    className="form-control" 
                                    placeholder='From Name' 
                                    value={currentRecord.mail_form_name}
                                    onChange={(e) => setCurrentRecord({ ...currentRecord, mail_form_name: e.target.value })}
                                    required 
                                  />
                                </div>
                                </div>
                                <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                                  <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}

            <div className="p-2"></div>
            <div className="page-body">
                
        
               <div className="titel-hrader table-responsive table-responsive-scrollbar-top">

               
                <table class="table  table-hover vw-100">
                <thead>
                    <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Mail Mailer</th>
                    <th scope="col">Mail Host</th>
                    <th scope="col">Mail Port</th>
                    <th scope="col">Mail UsernaMe</th>
                    <th scope="col">Mail password</th>
                    <th scope="col">Mail Encryption</th>
                    <th scope="col">Mail Form Address</th>
                    <th scope="col">Mail Form Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Action</th>
                    
                    </tr>
                </thead>
                <tbody>
                {reports.length === 0 ? (
                            <tr>
                                <td colSpan="11" className="text-center">No SMTP Data</td>
                            </tr>
                ) : (
                reports.map((report) => (
                    <tr key={report.id}>
                      <td>{report.id}</td>
                      <td>{report.mail_mailer}</td>
                      <td>{report.mail_host}</td>
                      <td>{report.mail_port}</td>
                      <td>{report.mail_username}</td>
                      <td>{report.mail_password}</td>
                      <td>{report.mail_encryption}</td>
                      <td>{report.mail_form_address}</td>
                      <td>{report.mail_form_name}</td>
                      <td>{report.created_at}</td>
                      <td>
                      <div class="row">
                        <div class="col-md-6">
                        <button type="button" class="btn btn-success"  onClick={() => handleShowModal(report)}><i class="bi bi-pencil-square"></i></button>
                        </div>
                        <div class="col-md-6">
                        <button type="button" class="btn btn-danger pt-1"  onClick={() => Delete(report.id)}><i class="bi bi-trash3-fill"></i></button>
                        </div>
                    </div>
                      </td>
                  
                 
                    </tr>
                  ))
                )}
                </tbody>
                </table>
                </div>
                <div className="d-flex justify-content-center">
                <nav aria-label="...">
                {limit < total &&(
                      <ul class="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                          <li key={index + 1} className={`page-item ${page === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => setPage(index + 1)}>
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                      )}
                </nav>
              </div>
            </div>

            
            </div>
        </div>
      </div>
    
    
    </div>
  );
};

export default Smpt;
