// src/components/Contact.js
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL || '';

const Emailtemplet = () => {
  const navigate = useNavigate();

  const EmailtempletForm = () => {
    navigate('/emailtempletfrom');
  };

  const [Search, setSearch] = useState('');


  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

const [total, setTotal] = useState('');


  const limit = 10;

  useEffect(() => {
    if (Search.trim()) {
      searchReports();
    } else {
      fetchReports();
    }
    
  }, [Search, page]);

  const fetchReports = async () => {
    try {
      const response = await axios.get(apiUrl+'/api/emailtemplet', {
        params: {
          limit,
          page,
          
        }
      });
      setReports(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);

    } catch (error) {
      console.error('Error fetching reports', error);
      
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };


  const searchReports = async () => {
    try {
      const response = await axios.get(apiUrl + '/api/searchemail', {
        params: {
          Search,
          limit,
          page
        }
      });


      setReports(response.data.data || []);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error searching reports', error);

    }
  };

  const Delete = (id) => {
    if (window.confirm('Are you sure you want to delete this data?')) {
    axios.delete(apiUrl+`/api/emailtemplet/${id}`)
      .then(response => {
        setReports(reports.filter(report => report.id !== id));

      })
      .catch(error => {
        console.error('There was an error deleting the record!', error);
      });
    }
  };

  return (
    <div>
          <div className="Website-list">
      <div className="col">
        <Navbar />
      </div>
      <div>
        <div className="pt-4"></div>
        <Sidebar />
        <div className="pt-5"></div>
          <div className="main-content">
        
  
        <div class="page-header">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="title">
                            <h4>Email Templet</h4>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                        <div className="row">
                          <div className="col">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                Menu
                            </li>
                            <li className="breadcrumb-item active text-danger" aria-current="page">
                            Email Templet
                            </li>
                            
                        </ol>
                          </div>
                          <div className="col">
                           
                          <input
                            type="text"
                            className='form-control'
                            placeholder='Search by Keywords'
                            value={Search}
                            onChange={handleSearchChange}
                          />
                          </div>
                        </div>
                        
                    </nav>
                    </div>
                    <div className="col">
                    <button type="button" class="btn btn-primary float-end" onClick={EmailtempletForm}> Add new email templet</button>
                    </div>
                    
                </div>
            </div>
            <div className="p-2"></div>
            <div className="page-body">
                
        
               <div className="titel-hrader">

               </div>
                <table class="table">
                <thead>
                    <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Email Templet Name</th>
                    <th scope="col">Email Templet Subject</th>
                  
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                   
                    </tr>
                </thead>
                <tbody>
                {reports.length === 0 ? (
                            <tr>
                                <td colSpan="6" className="text-center">No Email Templet</td>
                            </tr>
                ) : (
                reports.map((report) => (
                          <tr key={report.id}>
                            <td>{report.id}</td> 
                           
                            <td>{report.email_templet_name}</td>
                            <td>{report.email_templet_title}</td>
                            
                            <td className={report.status === 'Active' ? 'text-success' : 'text-danger'}>{report.status}</td>
                            <td>{report.created_at}</td>
                            
                            <td> 
                              
                            <div class="row m-0">
                              <div class="col-md-5">
                              <Link type="button" className='btn btn-success' to={`/edittemplet/${report.id}`}><i class="bi bi-pencil-square"></i></Link>
                              
                              </div>
                              <div class="col-md-5">
                              <button type="button" class="btn btn-danger pt-1"  onClick={() => Delete(report.id)} ><i class="bi bi-trash3-fill"></i></button>
                              </div>
                            </div>
                            </td>
                      
                          </tr>
                        ))
                      )}
                </tbody>
                </table>
      
            </div>
            <div className="d-flex justify-content-center">
                    <nav aria-label="...">
                    {limit < total &&(
                      <ul class="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                          <li key={index + 1} className={`page-item ${page === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => setPage(index + 1)}>
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                      )}
                    </nav>
              </div>
            
            </div>
        </div>
      </div>
   
    </div>
  );
};

export default Emailtemplet;
