// src/App.js

import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './FormComponent.css'

import Login from './components/Login';
import Authentication from './components/authentication';

import Home from './home';
import Websitelist from './components/websitelist';
import Campaignschedule from './components/Campaign-Schedule';
import Datalist from './components/Datalist';
import Emailtemplet from './components/email-templet';
import EmailtempletForm from './components/EmailtempletForm';
import Edittemplet from './components/Edittemplet';
import SMTP from './components/smtp';



function App() {



  return (
    <Router>
   
    <Routes>
      
      <Route path="/" element={<Home />} />
      <Route path="/login" component={Login}  element={<Login />} />
      {/* <Route path="/" element={<Websitelist />} /> */}
      <Route path="/website-list" element={<Authentication element={Websitelist} />} />
      <Route path="/website-list/:id" element={<Authentication element={Websitelist} />} />
      <Route path="/Campaign-schedule" element={<Authentication element={Campaignschedule} />} />
      <Route path="/data-list" element={<Authentication element={Datalist} />} />
      <Route path="/email-templet" element={<Authentication element={Emailtemplet} />} />
      <Route path="/emailtempletfrom" element={<Authentication element={EmailtempletForm} />} />
      <Route path="/edittemplet/:id" element={<Authentication element={Edittemplet} />} />
      <Route path="/smtp" element={<Authentication element={SMTP} />} />
    </Routes>
  </Router>
  );
}



export default App;
